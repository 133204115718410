<template>
  <div>
    <input
        :style="wrapperStyle"
        class="input"
        :class="{'error-st': errorMsg}"
        ref="input"
        :type="type"
        v-model="inputValue"
        min="0"
        :maxlength="maxLength"
        @keyup.enter="$emit('onEnter')"
        :placeholder="placeholder"
        @input="e => onKeyup(e.target.value)"
        autocomplete="new-password"
        :disabled="disabled"/>
  </div>
</template>
<script>
  import FormMixin from "@/mixins/FormMixin";
  export default {
    name: "LpText",
    mixins: [
      FormMixin
    ],
    props: {
      value: {
        type: [String, Number]
      },
      type: {
        type: String,
        default: 'text'
      },
      disabled: {
        type: Boolean,
        required: false
      },
      maxLength: {
        type: Number,
        required: 100
      },
      placeholder: {
        type: String,
        default: ''
      },
      errorMsg: {
        type: String,
        required: false,
        default: ''
      },
      width: {
        type: String
      },
      validate: {
        type: Object,
        default: function() {
          return {
            valid_required: false,
            error_msg: '',
            max_length: 100,
            min_length: 0,
            alpha_s: true,
            alpha_g: true,
            special: true,
            num: true
          }
        }
      }
    },
    created() {
      if(this.value) {
        this.inputValue = this.value;
        if(this.type === 'number') {
          if(typeof(this.inputValue) === String) {
            this.inputValue = this.inputValue.replace(/-/gi, '');
          }
        }
      }
      if(this.validate) {
        this.valid = this.validate;
      }
    },
    watch: {
      value(n) {
        this.inputValue = n;
      }
    },
    data() {
      return {
        inputValue: ''
      }
    },
    computed: {
      wrapperStyle() {
        let style = {};
        if(this.width) {
          style.width = this.width;
        }
        return style;
      }
    },
    methods: {
      onKeyup(value) {
        this.$emit('update:errorMsg', '');
        this.inputValue = value;
        if(this.type==='phone') {
          this.inputValue = this.autoHyphenPhone(value);
        } else if(this.type==='number') {
        //
        }
        this.$emit('update:value', this.inputValue);
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  input
    height 48px

  .error-st
    border 1px solid $error !important

input:disabled 
  color $sub3

</style>
